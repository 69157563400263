import { graphql } from 'gatsby'
import Collection from '../containers/Collection'

export default Collection

export const pageQuery = graphql`
  query allArticleByCategoryQuery(
    $skip: Int!
    $limit: Int!
    $slug: String!
    $imageQuality: Int!
  ) {
    collectionInfo: datoCmsCategory(slug: { eq: $slug }) {
      id: originalId
      name: title
      slug
    }

    posts: allDatoCmsArticle(
      filter: {
          category: {elemMatch: {slug: {eq: $slug}}}
      }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
      ...ArticlePagination
    }
  }
`
